<template>
  <v-list-item>
    <v-list-item-avatar width="70" height="70" class="my-1">
      <v-avatar size="70" v-if="item.picture">
        <v-img :src="item.picture" lazy-src="/img/nopic.jpg"></v-img>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content class="py-2">
      <v-list-item-title class="mb-2">{{ item.name }}</v-list-item-title>
      <v-list-item-subtitle class="mb-1">Documento: {{ item.document }}</v-list-item-subtitle>
      <v-list-item-subtitle class="mb-1">Referência: {{ item.reference }}</v-list-item-subtitle>
      <v-list-item-subtitle>OBS: {{ item.obs }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "ItemVisitantes",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style>
</style>