<template>
  <v-dialog v-model="value" persistent scrollable max-width="700">
    <v-card>
      <v-card-title class="text-h5 pr-4 pb-0">Iniciar uma Frota<v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <!-- Titulos dos passos -->
        <v-stepper v-model="step" class="rounded-0">
          <v-stepper-header>
            <!-- titulo passo 1 -->
            <v-stepper-step :complete="step > 1" step="1">Selecionar Motorista(s)</v-stepper-step>

            <v-divider></v-divider>

            <!-- titulo passo 2 -->
            <v-stepper-step :complete="step > 2" step="2">Selecionar Veículo(s)</v-stepper-step>

            <v-divider></v-divider>

            <!-- titulo passo 3 -->
            <v-stepper-step step="3"> Descrição da frota </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- passo 1 corpo -->
            <v-stepper-content step="1" class="pt-3">
              <ListaMotorista
                class="pt-2"
                @selectedItem="selectDriver"
                :selected="frota.driverIds"
                :multiple="true"
                ref="driverList"
              />
            </v-stepper-content>

            <!-- passo 2 corpo -->
            <v-stepper-content step="2" class="pt-3">
              <ListaVeiculos
                class="pt-2"
                @selectedItem="selectVehicle"
                :selected="frota.fleetVehicleIds"
                :multiple="true"
                ref="vehicleList"
              />
            </v-stepper-content>
            
            <!-- passo 3 corpo -->
            <v-stepper-content step="3" class="pb-0 finishScroll">
              <!-- card dos motoristas selecionados -->
              <v-card outlined class="mb-3 pb-2">
                <v-card-title class="py-2">Motoristas Selecionados</v-card-title>
                <v-list
                  class="py-0"
                  v-for="driver in getSelectedDriver()"
                  :key="driver.id"
                >
                  <ItemMotorista
                    :item="driver"
                  />
                </v-list>
              </v-card>
              
              <!-- card dos veiculos selecionados -->
              <v-card outlined class="mb-3 pb-1">
                <v-card-title class="py-2">Veículos Selecionados</v-card-title>
                <v-list
                  class="pt-0 pb-1"
                  v-for="vehicle in getSelectedVehicle()"
                  :key="vehicle.id"
                >
                  <ItemVeiculo
                    :item="vehicle"
                  />
                </v-list>
              </v-card>
              
              <!-- card das imagens -->
              <v-card outlined class="mb-7">
                <v-card-title class="py-2">Imagens</v-card-title>
                <v-card-text class="pa-3 pt-0">
                  <Webcam @file="addFile" @base64="addBase64" />
                  <div class="d-flex flex-wrap">
                    <div class="pa-1" style="width:25%" v-for="(img, idx) in frota.base64" :key="idx">
                      <v-card color="orange lighten-1 transition">
                        <v-btn text block @click="removeImage(idx, $event)">
                          <v-icon color="red">mdi-trash-can</v-icon>
                        </v-btn>
                        <v-img :src="img" width="100%"></v-img>
                      </v-card>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <!-- card dos dados da frota -->
              <v-card>
                <v-form ref="formMulti">
                  <v-text-field
                    v-model.number="frota.kmStart"
                    label="Km Inicial"
                    required
                    outlined
                    :rules="requiredField"
                    type="number"
                  ></v-text-field>
                  <v-textarea
                    v-model="frota.description"
                    :counter="255"
                    label="Descrição"
                    outlined
                  ></v-textarea>
                </v-form>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-divider></v-divider>
      <!-- ações do card -->
      <v-card-actions class="py-4">
        <v-btn
          v-if="step > 1"
          color="orange"
          text
          @click="prevStep"
        >
          <v-icon>mdi-arrow-left</v-icon> Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancelar</v-btn>

        <v-btn
          v-if="step < 3"
          color="success"
          :disabled="step == 1 && frota.driverIds.length == 0 || step == 2 && frota.fleetVehicleIds.length == 0"
          @click="nextStep"
        >
          Avançar
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-else
          color="success"
          @click="nextStep"
        >
          Iniciar Frota
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import ListaMotorista from "@/components/Motoristas/lista_motorista.vue";
import ListaVeiculos from "@/components/Veiculo/lista_veiculo.vue";
import ItemMotorista from "@/components/Motoristas/item_motorista.vue";
import ItemVeiculo from "@/components/Veiculo/item_veiculo.vue";
import Webcam from "@/components/Webcam/WbDialog.vue";
export default {
  name: "DialogFrotasMulti",
  components: {
    ListaMotorista,
    ListaVeiculos,
    ItemMotorista,
    ItemVeiculo,
    Webcam,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredField,
      dialog: false,
      step: 1,
      frota: {
        driverIds: [],
        fleetVehicleIds: [],
        description: "",
        kmStart: 0,
        images: [],
        base64: [],
      },
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
      this.step = 1;
      this.frota = {
        driverIds: [],
        fleetVehicleIds: [],
        description: "",
        kmStart: 0,
        images: [],
        base64: [],
      };
      this.$refs.driverList.clear();
      this.$refs.vehicleList.clear();
      this.$refs.formMulti.resetValidation();
    },
    nextStep() {
      if (this.step == 3) {
        if (!this.$refs.formMulti.validate()) {
          return;
        }
        // valida dos campos
        if (
          this.frota.driverIds.length == 0 ||
          this.frota.fleetVehicleIds.length == 0
        ) {
          this.$store.dispatch(
            "showErrorSnack",
            "Selecione pelo menos um Motorista, um Veículo e digite o KM Inicial!"
          );
          console.log("saiu");
          return;
        }
        // informa o callback
        this.frota.callback = () => {
          this.$store.dispatch(
            "showSuccessSnack",
            "Frota iniciada com sucesso!"
          );
          this.close();
        };
        this.$store.dispatch("registrarInicioFrota", this.frota);
      } else {
        this.step += 1;
      }
    },
    prevStep() {
      if (this.step == 1) {
        this.$emit("input", false);
        this.step = 1;
        this.$store.dispatch("showSnackColor", {
          text: "Operação cancelada",
          color: "amber darken-1",
        });
        return;
      }
      this.step -= 1;
    },
    selectDriver(item) {
      this.frota.driverIds = item;
      // this.nextStep();
    },
    selectVehicle(item) {
      this.frota.fleetVehicleIds = item;
      // this.nextStep();
    },
    getSelectedDriver() {
      return this.$store.getters.getDrivers.filter((item) =>
        this.frota.driverIds.includes(item.id)
      );
    },
    getSelectedVehicle() {
      return this.$store.getters.getFleetsVehicles.filter((item) =>
        this.frota.fleetVehicleIds.includes(item.id)
      );
    },
    addFile(file) {
      this.frota.images.push(file);
    },
    addBase64(base64) {
      this.frota.base64.push(base64);
    },
    removeImage(idx, event) {
      // adicionando efeito de transição ao remover a imagem
      let card = event.srcElement.closest(".transition");
      card.style.opacity = 0;
      setTimeout(() => {
        this.frota.base64.splice(idx, 1);
        this.frota.images.splice(idx, 1);
        card.style.opacity = 1;
      }, 200);
    },
  },
};
</script>

<style>
.finishScroll {
  overflow-y: auto;
  min-height: 300px;
}
</style>