<template>
  <v-dialog v-model="value" persistent scrollable max-width="700">
    <v-card>
      <v-card-title class="text-h5 pr-4 pb-0">Iniciar uma Frota<v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <!-- Titulos dos passos -->
        <v-stepper v-model="step" class="rounded-0">
          <v-stepper-header>
            <!-- titulo passo 1 -->
            <v-stepper-step :complete="step > 1" step="1">Selecionar Motorista</v-stepper-step>

            <v-divider></v-divider>

            <!-- titulo passo 2 -->
            <v-stepper-step :complete="step > 2" step="2">Selecionar Veículo</v-stepper-step>

            <v-divider></v-divider>

            <!-- titulo passo 3 -->
            <v-stepper-step step="3"> Descrição da frota </v-stepper-step>
          </v-stepper-header>


          <v-stepper-items>
            <!-- passo 1 corpo -->
            <v-stepper-content step="1" class="pt-3">
              <!-- class="mb-12"  -->
              <ListaMotorista
                class="pt-2"
                @selectedItem="selectDriver"
                :selected="[frota.driverId]"
                ref="driverList"
              />
            </v-stepper-content>

            <!-- passo 2 corpo -->
            <v-stepper-content step="2" class="pt-3">
              <ListaVeiculos
                class="pt-2"
                @selectedItem="selectVehicle"
                :selected="[frota.fleetVehicleId]"
                ref="vehicleList"
              />
            </v-stepper-content>

            <!-- passo 3 corpo -->
            <v-stepper-content step="3" class="pb-0">
              <!-- card do motorista selecionado -->
              <v-card outlined class="mb-3 pb-2">
                <v-card-title class="py-2">Motorista Selecionado</v-card-title>
                <ItemMotorista
                  v-if="step == 3"
                  :item="getSelectedDriver(frota.driverId)"
                />
              </v-card>

              <!-- card do veiculo selecionado -->
              <v-card outlined class="mb-3 pb-2">
                <v-card-title class="py-2">Veículo Selecionado</v-card-title>
                <ItemVeiculo
                  v-if="step == 3"
                  :item="getSelectedVehicle(frota.fleetVehicleId)"
                />
              </v-card>

              <!-- card das imagens -->
              <v-card outlined class="mb-7">
                <v-card-title class="py-2">Imagens</v-card-title>
                <v-card-text class="pa-3 pt-0">
                  <Webcam @file="addFile" @base64="addBase64" />
                  <div class="d-flex flex-wrap">
                    <div class="pa-1" style="width:25%" v-for="(img, idx) in frota.base64" :key="idx">
                      <v-card color="orange lighten-1 transition">
                        <v-btn text block @click="removeImage(idx, $event)">
                          <v-icon color="red">mdi-trash-can</v-icon>
                        </v-btn>
                        <v-img :src="img" width="100%"></v-img>
                      </v-card>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <v-form ref="formFrota">
                <v-text-field
                  v-model.number="frota.kmStart"
                  label="Km Inicial"
                  required
                  outlined
                  :rules="requiredField"
                  type="number"
                ></v-text-field>
                <v-textarea
                  v-model="frota.description"
                  :counter="255"
                  label="Descrição"
                  outlined
                ></v-textarea>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-divider></v-divider>
      <!-- ações do card -->
      <v-card-actions class="py-4">
        <v-btn
          v-if="step > 1"
          color="orange"
          text
          @click="prevStep"
        >
          <v-icon>mdi-arrow-left</v-icon> Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancelar</v-btn>

        <v-btn
          v-if="step < 3"
          color="success"
          :disabled="step == 1 && frota.driverId == null || step == 2 && frota.fleetVehicleId == null"
          @click="nextStep"
        >
          Avançar
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-else
          color="success"
          @click="nextStep"
        >
          Registrar Entrada
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import ListaMotorista from "@/components/Motoristas/lista_motorista.vue";
import ListaVeiculos from "@/components/Veiculo/lista_veiculo.vue";
import ItemMotorista from "@/components/Motoristas/item_motorista.vue";
import ItemVeiculo from "@/components/Veiculo/item_veiculo.vue";
import Webcam from "@/components/Webcam/WbDialog.vue";
export default {
  name: "DialogFrota",
  components: {
    ItemMotorista,
    ItemVeiculo,
    ListaMotorista,
    ListaVeiculos,
    Webcam,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredField,
      dialog: false,
      step: 1,
      frota: {
        driverId: null,
        fleetVehicleId: null,
        description: null,
        kmStart: null,
        images: [],
        base64: [],
      },
    };
  },
  methods: {
    clearFrota() {
      this.frota = {
        driverId: null,
        fleetVehicleId: null,
        description: null,
        kmStart: null,
        images: [],
        base64: [],
      },
      this.$refs.driverList.clear();
      this.$refs.vehicleList.clear();
    },
    close() {
      this.$emit("input", false);
      this.step = 1;
      this.clearFrota();
      this.$refs.formFrota.resetValidation();
    },
    nextStep() {
      if (this.step == 3) {
        // valida dos campos
        if (!this.$refs.formFrota.validate()) {
          return;
        }
        if (
          this.frota.driverId == null ||
          this.frota.fleetVehicleId == null
        ) {
          this.$store.dispatch(
            "showErrorSnack",
            "Selecione um veiculo e um motorista!"
          );
          return;
        }
        // informa o callback
        this.frota.callback = () => {
          this.$emit("input", false);
          this.step = 1;
          this.$store.dispatch(
            "showSuccessSnack",
            "Frota inciada com sucesso!"
          );
          this.clearFrota();
        };
        // envia a requisição
        this.$store.dispatch("regInicioFrota", this.frota);
      } else {
        this.step += 1;
      }
    },
    prevStep() {
      if (this.step == 1) {
        this.$emit("input", false);
        this.step = 1;
        this.$store.dispatch("showSnackColor", {
          text: "Entrada cancelada",
          color: "amber darken-1",
        });
        return;
      }
      this.step -= 1;
    },
    selectDriver(item) {
      this.frota.driverId = item;
      this.nextStep();
    },
    selectVehicle(item) {
      this.frota.fleetVehicleId = item;
      this.nextStep();
    },
    getSelectedDriver() {
      return (
        this.$store.getters.getDrivers.find(
          (item) => item.id == this.frota.driverId
        ) || {}
      );
    },
    getSelectedVehicle() {
      return (
        this.$store.getters.getFleetsVehicles.find(
          (item) => item.id == this.frota.fleetVehicleId
        ) || {}
      );
    },
    addFile(file) {
      this.frota.images.push(file);
    },
    addBase64(base64) {
      this.frota.base64.push(base64);
    },
    removeImage(idx, event) {
      // adicionando efeito de transição ao remover a imagem
      let card = event.srcElement.closest(".transition");
      card.style.opacity = 0;
      setTimeout(() => {
        this.frota.base64.splice(idx, 1);
        this.frota.images.splice(idx, 1);
        card.style.opacity = 1;
      }, 200);
    },
  },
};
</script>

<style>
.finishScroll {
  overflow-y: auto;
  min-height: 300px;
}
</style>