<template>
  <div class="pb-13">
    <!-- tabelas dos visitantes -->
    <v-card id="visitantes">
      <TablePresents
        :headers="headersVisitas"
        :items="getVisitas"
      />
    </v-card>
    <!-- tabelas dos veiculos -->
    <v-card id="veiculos" class="my-2">
      <TableVeiculos
        :headers="headersVeiculos"
        :items="getVeiculos"
      />
    </v-card>
    <!-- tabela das frotas -->
    <v-card id="frotas" class="my-2">
      <TableFrotaMulti
        :headers="headersFleetsMulti"
        :items="getFleetsMultiStarted"
      />
    </v-card>

    <v-card id="frotas-antiga">
      <TableFrotaSimples
        :headers="headersFleetsSimple"
        :items="getFleetStarted"
      />
    </v-card>
    <DialogVisita v-model="dialogVisited" :visitados="getVisitados" />
    <DialogVeiculo v-model="dialogVehicle" />
    <DialogFrotaMultipla v-model="dialogFrotaMultipla" />
    <DialogFrota v-model="dialogFrota" />

    <!-- Navegação lateral direita-->
    <v-navigation-drawer
      dark
      fixed
      floating
      right
      expand-on-hover
      class="rounded-lg rounded-br-0 rounded-tr-0 menu"
      style="top: calc(50% - 90px); height: auto;"
    >
      <v-list
        dense
        nav
      >
        <v-list-item link @click="$vuetify.goTo('#visitantes')">
          <v-list-item-icon><v-icon color="white">mdi-run</v-icon></v-list-item-icon>
          <v-list-item-title class="white--text">Visitantes Presentes</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="$vuetify.goTo('#veiculos')">
          <v-list-item-icon><v-icon color="white">mdi-car</v-icon></v-list-item-icon>
          <v-list-item-title class="white--text">Veículos Presentes</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="$vuetify.goTo('#frotas')">
          <v-list-item-icon><v-icon color="white">mdi-dump-truck</v-icon></v-list-item-icon>
          <v-list-item-title class="white--text">Frotas Iniciadas</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="$vuetify.goTo('#frotas-antiga')">
          <v-list-item-icon><v-icon color="white">mdi-dump-truck</v-icon></v-list-item-icon>
          <v-list-item-title class="white--text">Frotas Antiga</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- botão de entrada de visita -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          absolute
          bottom
          right
          fab
          fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialogVisited = true"
        >
          <v-icon>mdi-run</v-icon>
        </v-btn>
      </template>
      <span>Iniciar visita</span>
    </v-tooltip>
    <!-- entrada de veiculo -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          center
          bottom
          fab
          fixed
          class="mb-10"
          style="left: 50%"
          v-bind="attrs"
          v-on="on"
          @click="dialogVehicle = true"
        >
          <v-icon color="black">mdi-car</v-icon>
        </v-btn>
      </template>
      <span>Iniciar veiculo</span>
    </v-tooltip>
    <!-- inicar frota -->
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          bottom
          left
          fab
          fixed
          class="mb-10 ml-14"
          v-bind="attrs"
          v-on="on"
          @click="dialogFrotaMultipla = true"
        >
          <v-icon color="black">mdi-dump-truck</v-icon>
        </v-btn>
      </template>
      <span>Iniciar frota</span>
    </v-tooltip>
    <!-- Iniciar Frota SIMPLES-->
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          bottom
          fab
          fixed
          class="mb-10 forceQuarter"
          v-bind="attrs"
          v-on="on"
          @click="dialogFrota = true"
        >
          <v-icon color="black">mdi-dump-truck</v-icon>
        </v-btn>
      </template>
      <span>Iniciar frota Antiga</span>
    </v-tooltip>
  </div>
</template>

<script>
import TablePresents from "@/components/Visitas/TablePresents";
import TableVeiculos from "@/components/Veiculo/TableReportVeiculos";
import TableFrotaMulti from "@/components/Frota/TableFleetMulti";
import TableFrotaSimples from "@/components/Frota/TableFleetSimple";
import DialogVisita from "@/components/Efetivo/components/dialog_visita.vue";
import DialogVeiculo from "@/components/Efetivo/components/dialog_veiculo.vue";
import DialogFrotaMultipla from "@/components/Efetivo/components/dialog_frota_multi";
import DialogFrota from "@/components/Efetivo/components/dialog_frota";
import { mapGetters } from "vuex";
export default {
  name: "EfetivoHome",
  components: {
    TablePresents,
    TableVeiculos,
    DialogVisita,
    DialogVeiculo,
    TableFrotaMulti,
    DialogFrotaMultipla,
    DialogFrota,
    TableFrotaSimples,
  },
  computed: {
    ...mapGetters([
      "getVisitas",
      "getVisitados",
      "getVeiculos",
      "getFleetsMultiStarted",
      "getFleetStarted",
    ]),
  },
  data() {
    return {
      dialogVisited: false,
      dialogVehicle: false,
      dialogFrotaMultipla: false,
      dialogFrota: false,
      headersVisitas: [
        { text: "Visitante", value: "visitanteName" },
        { text: "Visitado", value: "visitadoName" },
        { text: "Descrição", value: "description" },
        { text: "Entrada", value: "createdAt" },
        //{ text: "Aprovação", value: "aprovText" },
        //{ text: "Grupo", value: "groupId" },
        { text: "Ações", value: "actions", filterable: false, sortable: false, width: 45,},
      ],
      headersVeiculos: [
        { text: "Imagens Iniciais", value: "picturesEnter", sortable: false, filterable: false, align: "center", width: 70, },
        { text: "Placa", value: "placa" },
        { text: "Descrição", value: "description" },
        { text: "Entrada", value: "createdAt" },
        { text: "Visita", filterable: false, sortable: false, value: "visitaId", width: 450, },
        { text: "Ações", value: "actions", filterable: false, sortable: false, width: 45, },
      ],
      headersFleetsMulti: [
        { text: "Imagens Iniciais", value: "pictures", align:'center', sortable: false, filterable: false, width: 70 },
        { text: "Motoristas Iniciais", value: "drivers", sortable: false, filterable: false, width: 300 },
        { text: "Veículos Iniciais", value: "fleetVehicles", sortable: false, filterable: false, width: 300 },
        { text: "Descrição Inicial", value: "description" },
        { text: "Início", value: "createdAt" },
        { text: "Km Inicial", value: "kmStart" },
        { text: "Ações", value: "actions", sortable: false, filterable: false, width: 45, },
      ],
      headersFleetsSimple: [
        { text: "Imagens Iniciais", value: "picturesStart", align:'center', sortable: false, filterable: false, width: 70 },
        { text: "Motorista", value: "driver", width: 300 },
        { text: "Veículo", value: "vehicle", width: 300 },
        { text: "Descrição", value: "description" },
        { text: "Início", value: "createdAt" },
        { text: "Km Inicial", value: "kmStart" },
        { text: "Ações", value: "actions", sortable: false, filterable: false, width: 45, },
      ],
    };
  },
  created() {
    this.$store.dispatch("loadVisitados");
    this.$store.dispatch("loadVisitantes");
    this.$store.dispatch("loadVisitas");
    this.$store.dispatch("loadVeiculos2");
    this.$store.dispatch("loadFleetsMultiStarted");
    this.$store.dispatch("loadDrivers");
    this.$store.dispatch("loadFleetsVehicles");
    this.$store.dispatch("loadFleetsStarted2");
  },
};
</script>

<style>
.forceQuarter {
  bottom: 5%;
  left: calc(25% + 35px)
}
</style>