<template>
  <div>
    <v-virtual-scroll
      :items="getVisitas"
      bench="1"
      item-height="115"
      height="227"
    >
      <template v-slot:default="{ item }">
        <v-card
          outlined
          :color="selected.includes(item.id) ? 'green lighten-2' : ''"
          :class="getVisitas.length > 2 ? 'mr-1' : ''"
          @click="selectItem(item)"
        >
          <Item :item="item" />
        </v-card>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Item from "@/components/Efetivo/components/item_visita.vue";
export default {
  name: "ListaVisita",
  computed: {
    ...mapGetters(["getVisitas"]),
  },
  components: {
    Item,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  methods: {
    selectItem(item) {
      if (this.multiple) {
        if (this.selectedItems.includes(item.id)) {
          this.selectedItems = this.selectedItems.filter((i) => i !== item.id);
        } else {
          this.selectedItems.push(item.id);
        }
        this.$emit("selectedItem", this.selectedItems);
      } else {
        this.$emit("selectedItem", item.id);
      }
    },
  },
};
</script>

<style>
</style>