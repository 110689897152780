<template>
  <v-dialog v-model="value" persistent scrollable max-width="700">
    <v-card>
      <v-card-title class="text-h5 pr-4 pb-4">Entrada de Veículo<v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-card outlined class="mb-3">
          <v-card-title class="pt-2">Visita</v-card-title>
          <v-card-subtitle>Selecione uma visita para vincular ao veículo ou não selecione para registrar sem vínculo.</v-card-subtitle>
          <v-card-text v-if="!veiculo.visitaId">
            <ListaVisita @selectedItem="selectVisita" />
          </v-card-text>
          <ItemVisita
            :item="getSelectedVisita()"
            v-else
            :remove="true"
            @remove="veiculo.visitaId = null"
          />
        </v-card>
        <!-- card com as imagens -->
        <v-card outlined class="mb-7">
          <v-card-title class="py-2">Imagens</v-card-title>
          <v-card-text class="pa-3 pt-0">
            <Webcam @file="addFile" @base64="addBase64" />
            <div class="d-flex flex-wrap">
              <div class="pa-1" style="width:25%" v-for="(img, idx) in veiculo.base64" :key="idx">
                <v-card color="orange lighten-1 transition">
                  <v-btn text block @click="removeImage(idx, $event)">
                    <v-icon color="red">mdi-trash-can</v-icon>
                  </v-btn>
                  <v-img :src="img" width="100%"></v-img>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-form ref="formVeiculo">
          <v-text-field
            v-model="veiculo.placa"
            :rules="requiredField"
            v-mask="'AAA-#N##'"
            label="Placa do Veículo"
            required
            outlined
            @input="veiculo.placa = veiculo.placa.toUpperCase()"
          ></v-text-field>
          <v-textarea
            v-model="veiculo.description"
            :counter="255"
            label="Descrição"
            outlined
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <!-- ações do card -->
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancelar">Cancelar</v-btn>
        <v-btn
          color="success"
          @click="registrarEntrada"
        >
          Registrar Entrada
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ListaVisita from "./lista_visitas.vue";
import ItemVisita from "./item_visita.vue";
import Webcam from "@/components/Webcam/WbDialog.vue";
import { requiredField } from "../../../Utils/InputValidation";
export default {
  name: "DialogVeiculo",
  components: {
    ListaVisita,
    ItemVisita,
    Webcam,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      requiredField,
      veiculo: {
        visitaId: null,
        placa: null,
        description: null,
        images: [],
        base64: [],
      },
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
      this.step = 1;
    },
    registrarEntrada() {
      if (!this.$refs.formVeiculo.validate())
        return;
      this.veiculo.callback = () => {
        this.cancelar();
      };
      this.$store.dispatch("registrarEntradaVeiculo", this.veiculo);
    },
    cancelar() {
      this.veiculo = {
        visitaId: null,
        placa: null,
        description: null,
        images: [],
        base64: [],
      };
      this.$emit("input", false);
      this.$refs.formVeiculo.resetValidation();
    },
    selectVisita(item) {
      this.veiculo.visitaId = item;
    },
    getSelectedVisita() {
      return this.$store.getters.getVisitas.find(
        (item) => item.id == this.veiculo.visitaId
      );
    },
    addFile(file) {
      this.veiculo.images.push(file);
    },
    addBase64(base64) {
      this.veiculo.base64.push(base64);
    },
    removeImage(idx, event) {
      // adicionando efeito de transição ao remover a imagem
      let card = event.srcElement.closest(".transition");
      card.style.opacity = 0;
      setTimeout(() => {
        this.veiculo.base64.splice(idx, 1);
        this.veiculo.images.splice(idx, 1);
        card.style.opacity = 1;
      }, 200);
    },
  },
};
</script>

<style>
.finishScroll {
  overflow-y: auto;
  min-height: 300px;
}
</style>