<template>
  <div>
    <div class="d-flex">
      <v-text-field
        v-model="filter"
        label="Pesquisa"
        outlined
        hide-details
        class="mb-5 mr-4"
        @input="$store.commit('setFilterVisitante', $event)"
      ></v-text-field>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="amber lighten-1"
            dark
            fab
            elevation="0"
            v-bind="attrs"
            v-on="on"
            @click="$emit('addVisitante')"
          >
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Adicionar Visitante</span>
      </v-tooltip>
    </div>
    <v-virtual-scroll
      v-if="getVisitantesFilter.length"
      :items="getVisitantesFilter"
      bench="1"
      :item-height="scrollItemHeight"
      :height="scrollHeight"
    >
      <template v-slot:default="{ item }">
        <v-card
          outlined
          :color="selected.includes(item.id) ? 'green lighten-2' : ''"
          :class="getVisitantesFilter.length > 3 ? 'mr-1' : ''"
          @click="selectItem(item)"
        >
          <ItemVisitante :item="item" />
        </v-card>
      </template>
    </v-virtual-scroll>
    <div v-else :style="`height: ${scrollHeight}px`" class="d-flex justify-center align-center">
      <span v-if="filter">Nenhum dado encontrado</span>
      <span v-else>Não há dados disponíveis</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemVisitante from "@/components/Efetivo/components/item_visitantes.vue";
export default {
  name: "ListaVisitantes",
  computed: {
    ...mapGetters(["getVisitantes", "getVisitantesFilter"]),
  },
  components: {
    ItemVisitante,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItems: [],
      filter: "",
      scrollHeight: 320,
      scrollItemHeight: 108,
    };
  },
  methods: {
    selectItem(item) {
      if (this.multiple) {
        if (this.selectedItems.includes(item.id)) {
          this.selectedItems = this.selectedItems.filter((i) => i !== item.id);
        } else {
          this.selectedItems.push(item.id);
        }
        this.$emit("selectedItem", this.selectedItems);
      } else {
        this.$emit("selectedItem", item.id);
      }
    },
    clear() {
      this.filter = "";
      this.$store.commit("setFilterVisitante", "");
    }
  },
};
</script>

<style>
</style>