<template>
  <v-dialog v-model="value" persistent scrollable max-width="700">
    <v-card>
      <v-card-title class="text-h5 pr-4 pb-0">Iniciar uma Visita<v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <!-- Titulos dos passos -->
        <v-stepper v-model="step" class="rounded-0">
          <v-stepper-header>
            <!-- titulo passo 1 -->
            <v-stepper-step :complete="step > 1" step="1">Selecionar Visitado</v-stepper-step>

            <v-divider></v-divider>

            <!-- titulo passo 2 -->
            <v-stepper-step :complete="step > 2" step="2">Selecionar Visitante</v-stepper-step>

            <v-divider></v-divider>

            <!-- titulo passo 3 -->
            <v-stepper-step step="3"> Descrição da visita </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- passo 1 corpo -->
            <v-stepper-content step="1" class="pt-3">
              <ListaVisitados
                class="pt-2"
                :selected="[visita.visitadoId]"
                @selectedItem="selectVisitado"
                @addVisitado="dialogVisitado = true"
                ref="visitadoList"
                />
              <!-- <ListaVisitados /> -->
            </v-stepper-content>

            <!-- passo 2 corpo -->
            <v-stepper-content step="2" class="pt-3">
              <!-- class="mb-12" -->
              <ListaVisitantes
                class="pt-2"
                :selected="[visita.visitanteId]"
                @selectedItem="selectVisitante"
                @addVisitante="dialogVisitante = true"
                ref="visitanteList"
              />
            </v-stepper-content>
            <!-- passo 3 corpo -->
            <v-stepper-content step="3" class="pb-0">
              <!-- class="mb-12" -->
              <div class="finishScroll">
                <v-card outlined class="mb-3 pb-2" v-if="visita.visitadoId">
                  <v-card-title class="py-2">Visitado Selecionado</v-card-title>
                  <ItemVisitado :item="getSelectedVistado(visita.visitadoId)" />
                </v-card>
                <v-card outlined class="mb-3 pb-1" v-if="visita.visitanteId">
                  <v-card-title class="py-2">Visitante Selecionado</v-card-title>
                  <ItemVisitante :item="getSelectedVistantes(visita.visitanteId)" />
                </v-card>
                <v-textarea
                  v-model="visita.description"
                  :counter="255"
                  label="Descrição"
                  outlined
                ></v-textarea>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-divider></v-divider>
      <!-- ações do card -->
      <v-card-actions class="py-4">
        <v-btn
          v-if="step > 1"
          color="orange"
          text
          @click="prevStep"
        >
          <v-icon>mdi-arrow-left</v-icon> Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancelar</v-btn>
        <v-btn
          v-if="step < 3"
          color="success"
          :disabled="step == 1 && visita.visitadoId == null || step == 2 && visita.visitanteId == null"
          @click="nextStep"
        >
          Avançar
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-else
          color="success"
          @click="nextStep"
        >
          Registrar Entrada
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogVisitado v-model="dialogVisitado" :item="itemVisitado" @close="itemVisitado = {}" @save="salvarVisitado" />
    <DialogVisitante v-model="dialogVisitante" :item="itemVisitante" @close="itemVisitante = {}" @save="salvarVisitante" />
  </v-dialog>
</template>

<script>
import ListaVisitados from "./lista_visitados.vue";
import ListaVisitantes from "./lista_visitantes.vue";
import ItemVisitado from "./item_visitado.vue";
import ItemVisitante from "./item_visitantes.vue";
import DialogVisitado from "../../Visitados/Dialog_visitado.vue";
import DialogVisitante from "../../Visitantes/Dialog_visitante.vue";
export default {
  name: "DialogVisita",
  components: {
    ListaVisitados,
    ListaVisitantes,
    ItemVisitado,
    ItemVisitante,
    DialogVisitado,
    DialogVisitante,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      dialogVisitado: false,
      dialogVisitante: false,
      itemVisitado: {},
      itemVisitante: {},
      step: 1,
      visita: {
        visitanteId: null,
        visitadoId: null,
        description: null,
      },
    };
  },
  methods: {
    clearVisita() {
      this.visita = {
        visitanteId: null,
        visitadoId: null,
        description: null,
      };
      this.$refs.visitadoList.clear();
      this.$refs.visitanteList.clear();
    },
    close() {
      this.$emit("input", false);
      this.step = 1;
      this.clearVisita();
    },
    nextStep() {
      if (this.step == 3) {
        // valida dos campos
        if (this.visita.visitadoId == null || this.visita.visitanteId == null) {
          this.$store.dispatch(
            "showErrorSnack",
            "Selecione um visitado e um visitante!"
          );
          return;
        }
        // informa o callback
        this.visita.callback = () => {
          this.$emit("input", false);
          this.step = 1;
          this.$store.dispatch(
            "showSuccessSnack",
            "Entrada registrada com sucesso!"
          );
          this.clearVisita();
        };
        this.$store.dispatch("registrarEntrada", this.visita);
      }
      this.step++;
    },
    prevStep() {
      if (this.step == 1) {
        this.$emit("input", false);
        this.step = 1;
        this.$store.dispatch("showSnackColor", {
          text: "Entrada cancelada",
          color: "amber darken-1",
        });
        return;
      }
      this.step -= 1;
    },
    selectVisitado(item) {
      this.visita.visitadoId = item;
      this.nextStep();
    },
    selectVisitante(item) {
      this.visita.visitanteId = item;
      this.nextStep();
    },
    getSelectedVistado() {
      return this.$store.getters.getVisitados.find(
        (item) => item.id == this.visita.visitadoId
      );
    },
    getSelectedVistantes() {
      return this.$store.getters.getVisitantes.find(
        (item) => item.id == this.visita.visitanteId
      );
    },
    salvarVisitado(item) {
      item.callback = () => {
        this.dialogVisitado = false;
        this.itemVisitado = {};
        this.$store.dispatch("loadVisitados");
      };
      this.$store.dispatch("saveVisitado2", item);
    },
    salvarVisitante(item) {
      item.callback = () => {
        this.dialogVisitante = false;
        this.itemVisitante = {};
        this.$store.dispatch("loadVisitantes");
      };
      this.$store.dispatch("saveVisitante2", item);
    },
  },
};
</script>

<style>
.finishScroll {
  overflow-y: auto;
  min-height: 300px;
}
</style>